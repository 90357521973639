import React from "react";
import {loadableP5 as P5Wrapper} from './loadable';

import BoxSketch from "./boxSketch";


export default function P5Box(){
    
    //let size = WindowSize();

    return <P5Wrapper sketch={BoxSketch}/>;
}