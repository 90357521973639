import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as homeStyles from "../styles/home.module.css"
import Header from "../components/header"
import Footer from "../components/footer"
import Seo from "../components/seo"
import OrthoBox from "../components/P5/boxComponent"
import exclamation from "../images/heroEx.png"
import downArrow from "../assets/home/down_arrow.svg"

const heroString = `{code}`

const IndexPage = () => (
  <main id={homeStyles.pageContainer}>
    <Seo title="Home" />

    <Header home={true}/>
    <section id={homeStyles.heroContainer}>
      <div id={homeStyles.heroLeft}>
        <div id={homeStyles.heroText}>
          <span style={{paddingRight: 6}}>Well, hello</span><img src={exclamation} alt="!" id={homeStyles.exclamation} />
        </div>

        <div id={homeStyles.imRyan}>
          I'm Ryan Winstead
        </div>
        <div id={homeStyles.buildCodeText}>
          I like to build delightful things with <span class={homeStyles.purpleText}>{heroString}</span>
        </div>
      </div>

      <div id={homeStyles.orthoBox}>
        <OrthoBox/>
      </div>

      <div id={homeStyles.mobileSubtext}>
        <div id={homeStyles.mobileImRyan}>
          I'm Ryan Winstead
        </div>
        <div id={homeStyles.mobileBuildCode}>
          I like to build delightful things with <span class={homeStyles.purpleText}>{heroString}</span>
        </div>
      </div>
    </section>

    <div id={homeStyles.workArrow}>
        <div id={homeStyles.workText}>work</div>
        <img src={downArrow} alt="Downward arrow" id={homeStyles.downArrow} />
    </div>

    <section id={homeStyles.projectsContainer}>
      <div id={homeStyles.projectsHeader}>
        Latest projects
      </div>

      <div id={homeStyles.projectsGrid}>
        <div class={homeStyles.projectItem}>
          <div class={homeStyles.projectImage}>
            <Link to="/my-house-my-rights">
              <StaticImage src={'../assets/home/MiCasa_home.png'} alt="QR Cup project image" class={homeStyles.projectImageInner}/>
            </Link>
            <div class={homeStyles.imageTag}>
              Web dev & UX
            </div>
          </div> 
          <div class={homeStyles.projectTitle}>
            My House My Rights
          </div>
          <div class={homeStyles.projectDescription}>
            A website that uses storytelling to help immigrant families of Atlanta understand and exercise their rights as tenants. In partnership with the Atlanta city government
          </div>
        </div>

        <div class={homeStyles.projectItem}>
        <div class={homeStyles.projectImage}>
          <Link to="/no-more-evictions">
            <StaticImage src={'../assets/home/NoMoreEvictions_home.png'} alt="QR Cup project image" class={homeStyles.projectImageInner}/>
          </Link>
            <div class={homeStyles.imageTag}>
              Web dev & UX
            </div>
          </div>          
          <div class={homeStyles.projectTitle}>
            No More Evictions
          </div>
          <div class={homeStyles.projectDescription}>
            An online tool that allows immigrant families to easily file for the CDC moratorium on evictions. In partnership with the Atlanta city government          </div>
        </div>

        <div class={homeStyles.projectItem}>
            <div class={homeStyles.projectImage}>
              <Link to="/dinostore">
                <StaticImage src={'../assets/home/Dino_home.png'} alt="QR Cup project image" class={homeStyles.projectImageInner}/>
              </Link>
              <div class={homeStyles.imageTag}>
                Unity
              </div>
          </div>          
          <div class={homeStyles.projectTitle}>
            Dino-Store
          </div>
          <div class={homeStyles.projectDescription}>
          A game that helps people understand the risks of COVID transmission in public </div>
        </div>

        <div class={homeStyles.projectItem}>
          <div class={homeStyles.projectImage}>
          <Link to="/qr-cup">
              <StaticImage src={'../assets/home/QRCup_Home.png'} alt="QR Cup project image" class={homeStyles.projectImageInner}/>
          </Link>
            <div class={homeStyles.imageTag}>
              UX design & research
            </div>
          </div>
          <div class={homeStyles.projectTitle}>
            QR Cup
          </div>
          <div class={homeStyles.projectDescription}>
          A reusable cup that orders for you via a QR code and locates nearby cafés      </div>
        </div>

      </div>


      <section id={homeStyles.experimentsContainer}>
        <div id={homeStyles.experimentsContent}>
          <div id={homeStyles.expHeader}>
            Featured<div id={homeStyles.expHeaderSpace}/> experiments
          </div>

          <div id={homeStyles.expItems}>
            <a href="https://www.shadertoy.com/view/NtX3DX" target="_blank" rel="noreferrer" id={homeStyles.expItem}>
              <div class={homeStyles.expItemNumber}>
                01
              </div>
              <div class={homeStyles.expImgWrapper}>
                <StaticImage src="../assets/home/truchet_img.png" alt="Image of truchet shader" class={homeStyles.expItemImg} quality={100}/>
              </div>
              <div class={homeStyles.expItemText}>
                <div class={homeStyles.expItemUpText}>
                  openGL
                </div>
                <div class={homeStyles.expItemBotText}>
                  Truchet Shader
                </div>
              </div>
            </a>

            <a href="https://rwinstead.github.io/Women-of-the-Olympics/" target="_blank" rel="noreferrer" id={homeStyles.expItem}>
              <div class={homeStyles.expItemNumber}>
                  02
              </div>
              <div class={homeStyles.expImgWrapper}>
                <StaticImage src="../assets/home/olympic_women_home.png" alt="Image of truchet shader" class={homeStyles.expItemImg} quality={100} />
              </div>
              <div class={homeStyles.expItemText}>
                <div class={homeStyles.expItemUpText}>
                  JS + Data Vis
                </div>
                <div class={homeStyles.expItemBotText}>
                  Olympic Women
                </div>
              </div>
            </a>

            <a href="https://rwinstead.itch.io/trap-gladiators" target="_blank" rel="noreferrer" id={homeStyles.expItem}>
              <div class={homeStyles.expItemNumber}>
                    03
              </div>
              <div class={homeStyles.expImgWrapper}>
                <StaticImage src="../assets/home/trapG2_home.png" alt="Image of trap gladiators game" class={homeStyles.expItemImg} quality={100} />
              </div>
              <div class={homeStyles.expItemText}>
                <div class={homeStyles.expItemUpText}>
                  Unity
                </div>
                <div class={homeStyles.expItemBotText}>
                  Trap Gladiators
                </div>
              </div>
            </a>
            <div id={homeStyles.expBottomSpacer}/>

          </div>

        </div>
      </section>
      <Footer/>

    </section>
</main>
)

export default IndexPage
