export default function sketch (p) {
    let rotationSpeed = .3;

    let l = 62;
    
    let c1;
    let c2;
    
    let state1 = false;
    let state2 = false;
    let state3 = false;
    let state4 = false;
    
    let phase1;
    let phase2;
    let phase4;
    
    let counter = 0;

    let scaleSize = 0;
    
    p.setup = function()
    {

      if(p.windowWidth > 1310) {
        p.createCanvas(500, 550, p.WEBGL);
        scaleSize = 1.1;
      }

      if(p.windowWidth <= 1310) {
        p.createCanvas(400, 450, p.WEBGL);
        scaleSize = 1.0;
      }

      if(p.windowWidth <= 1100) {
        p.createCanvas(380, 415, p.WEBGL);
        scaleSize = .9;
      }

      if(p.windowWidth <= 900) {
        p.createCanvas(300, 315, p.WEBGL);
        scaleSize = .7;
      }


      //p.createCanvas(500, 550, p.WEBGL);
      p.angleMode(p.DEGREES);
    }
    
    p.draw = function()
    {
    
      p.scale(scaleSize)
      p.ortho(-p.width / 2, p.width / 2, p.height / 2, -(p.height) / 2, 1000, 0);
      //p.orbitControl();
      p.background('#2A2D33');
    
      if(p.frameCount > 20){
        setState();
        counter+=1;
    }
    
      //INSIDE CUBE
      p.push();
        if(state3)
        {
          p.rotateX(-35);
          p.rotateY(135);
          p.rotateY((counter - 138)*rotationSpeed);
          p.translate(-(l/2), -(l/2), (l/2));
          p.noStroke();
    
          for (let i=0; i<6; i++)
            {
    
              if(i === 0) //DARKER PURPLE _BACK FACE_ GOES LEFT
              {
                p.push();
                  c1="#9D70FF";
                  c2="#9D70FF";
    
                p.push();
                if(state4) {
                  p.translate(-29.33*phase4, -29.33*phase4, 89.33 * phase4 );
                }
    
                p.fill(c1);
                p.triangle(0,0,0,l,l,0);
                p.pop();
    
                p.push();
                if(state4) {
                  p.translate(29.33*phase4, 29.33*phase4, 89.33 * phase4 );
                }
                p.fill(c2);
                p.triangle(l,0,l,l,0,l);
                p.pop();
                p.pop();
                
              }
              
              
              if(i === 1) //LIGHTER PURPLE _LEFT_ GOES LEFT
                {
                  p.push();
                  c1="#47197A";
                  c2="#47197A";
    
                  if(!state4) {
                    p.rotateY(90);
                  }
    
                  p.push();
                  if(state4) {
                    p.translate(-88.5 * phase4, -29.7*phase4, -29.7*phase4);
                    p.rotateY(90);
                  }
    
                  p.fill(c1);
                  p.triangle(0,0,l,0,l,l);
                  p.pop();
    
                  p.push();
                  if(state4) {
                    p.translate(-88.5 * phase4, 29.7*phase4, 29.7*phase4);
                    p.rotateY(90);
                  }
                  p.fill(c2);
                  p.triangle(0,0,0,l,l,l);
                  p.pop();
                  p.pop();
                }
                
              
              if(i===2) // LIGHTEST PURPLE _BOTTOM_ SPLIT
                {
                  p.push();
                  c1="#47197A";
                  c2="#CAB1FF";
                  if(!state4) {
                    p.rotateX(-90);
                  }
                  p.fill(c1);
                  p.push();
                    if(state4) {
                      p.translate((-29.7)*phase4, -88.5* phase4, 29.7*phase4);
                      p.rotateX(-90);
                    }
                    p.triangle(0,0,0,l,l,0);
                  p.pop();
                  p.push();
                    if(state4) {
                      p.translate((29.7)*phase4, -88.5 * phase4, -29.7*phase4);
                      p.rotateX(-90);
                    }
                    p.fill(c2);
                    p.triangle(l,0,l,l,0,l);
                    p.pop();
                    p.pop();
                }
                
              
              if(i===3) { // DARK PURPLE _RIGHT_ GOES RIGHT
                p.push();
                  c1="#47197A";
                  c2="#47197A";
    
                  if(!state4){
                    p.translate(l,0,0);
                    p.rotateY(90);
                  }
    
                  p.push();
    
                    if(state4) {
                        p.translate(89.33 * phase4, -29.33*phase4, -29.33*phase4);
                        p.translate(l,0,0);
                        p.rotateY(90);
                    }
    
    
                    p.fill(c1);
                    p.triangle(0,0,l,0,l,l);
                    p.pop();
    
                    p.push();
                    if(state4) {
                        p.translate(89.33 * phase4, 29.33*phase4, 29.33*phase4);
                        p.translate(l,0,0);
                        p.rotateY(90);
                    }
                    p.fill(c2);
                    p.triangle(0,0,0,l,l,l);
                    p.pop();
                    p.pop();
              }
              
              
              if(i===4) // LIGHTER PURPLE _TOP_ SPLIT
                {
                    p.push();
                  if(!state4){
                    p.rotateX(-90);
                    p.translate(0,0,l);
                  }
                  c1="#CAB1FF";
                  c2="#CAB1FF";
                  p.fill(c1);
                  p.push();
                  if(state4){
                    p.translate(-29.7*phase4 ,88.5*phase4, 30*phase4);
                    p.rotateX(-90);
                    p.translate(0,0,l);
                  }
                  p.triangle(0,0,0,l,l,0);
                  p.pop();
    
                  p.push();
                    if(state4) {
                        p.translate(29.7*phase4,88.5*phase4, -29.7*phase4);
                        p.rotateX(-90);
                        p.translate(0,0,l);
                    }
                    p.fill(c2);
                    p.triangle(l,0,l,l,0,l);
                    p.pop();
                    p.pop();
                }
                
              
              if(i===5) // LIGHTEST PURPLE /FRONT
                {
                    p.push();
                  c1="#9D70FF";
                  c2="#9D70FF";
    
                  if(!state4) {
                    p.translate(0,0,-l);
                  }
    
                  p.push();
                      if(state4) {
                        p.translate(-29.7*phase4, -29.7*phase4, -88.5*phase4);
                        p.translate(0,0,-l);
                      }
    
                      p.fill(c1);
                      p.triangle(0,0,0,l,l,0);
                      p.pop();
                      p.push();
                      if(state4) {
                        p.translate(29.7*phase4, 29.7*phase4, -88.5*phase4);
                        p.translate(0,0,-l);
                      }
                      p.fill(c2);
                      p.triangle(l,0,l,l,0,l);
                      p.pop();
                      p.pop();
                }
                
              
            }
          }
      
          p.pop();
    
    
      //OUTLINE CUBE ***************************************************
    
      p.push();
      p.rotateX(-35);
      p.rotateY(45);
        if(state3){
            p.rotateY((counter - 138)*rotationSpeed);
        }
        p.noFill();
        
        p.stroke("white");
        p.strokeWeight(2);
        p.box(250);
        p.pop();
    
    
        p.push();
    
      if(!state3) {
    
        p.noStroke();
    
    
        if(state1)
        {
            p.rotateZ(360*phase1);
        }
    
        p.push(); // LEFT BOTTOM
        p.fill("#47197A");
    
        p.translate(-42, -72.7461);
    
            if(state2) {
                p.translate(42 * phase2, 72.7461 * phase2);
            }
    
    
            p.triangle(0, 0 + 0, 0, 0 + -50, 0 + (-25*p.sqrt(3)), 0 + -25);
    
    
            p.pop();
    
            p.push();
            p.fill("#CAB1FF"); //LEFT TOP -- pinkish red
    
            p.translate(-42, 72.7461);
            if(state2) {
                p.translate(42 * phase2, -72.7461 * phase2);
            }
            p.triangle(0, 0, 0, 50, -25*p.sqrt(3), 25);
            p.pop();
    
            p.push(); //RIGHT
            p.fill("#9D70FF");
            p.translate(84.25, 0 );
            if(state2) {
                p.translate(-84.25 * phase2, 0);
            }
            p.triangle(0, 0, 25*p.sqrt(3), 25, 25*p.sqrt(3),-25);
            p.pop();
    
            p.push(); // LEFT
            p.fill("#47197A");
            p.translate(-84.25, 0);
            if(state2) {
                p.translate(84.25 * phase2, 0);
            }
            p.triangle(0, 0, -25*p.sqrt(3), 25, -25*p.sqrt(3),-25);
            p.pop();
    
            p.push(); //RIGHT BOTTOM
            p.fill("#9D70FF");
            p.translate(42, -72.7461);
            if(state2) {
                p.translate(-42 * phase2, 72.7461 * phase2);
            }
            p.triangle(0, 0, 0, -50, 25*p.sqrt(3), -25);
            p.pop();
    
            p.push(); //RIGHT TOP
            p.fill("#CAB1FF");
            p.translate(42, 72.7461);
            if(state2) {
                p.translate(-42 * phase2, -72.7461 * phase2);
            }
            p.triangle(0, 0, 0, 50, 25*p.sqrt(3), 25);
            p.pop();
        }
    
        p.pop(); 
    
    }
    
    function setState() {
      
        if(counter >= 1) {
          state1 = true;
          phase1 = p.min( (counter)/100, 1);
        }
    
        if(counter >= 101) {
          state2 = true;
          phase2 = p.min( (counter-101)/30, 1);
        }
    
        if(counter >= 138) {
          state3 = true;
        }
    
        if(counter >= 138 + (rotationSpeed * 1000)) {
          state4 = true;
          phase4 = p.min( (counter-438)/300, 1);
        }
    
        if(counter >= 740) {
          state1 = false;
          state2 = false;
          state3 = false;
          state4 = false;
          counter = 0;
        }
    }

    p.windowResized = function() {
      if(p.windowWidth > 1310) {
        p.resizeCanvas(500, 550, p.WEBGL);
        scaleSize = 1.1;
      }

      if(p.windowWidth <= 1310) {
        p.resizeCanvas(400, 450, p.WEBGL);
        scaleSize = 1.0;
      }

      if(p.windowWidth <= 1100) {
        p.resizeCanvas(380, 415, p.WEBGL);
        scaleSize = .85;
      }

      if(p.windowWidth <= 900) {
        p.resizeCanvas(300, 315, p.WEBGL);
        scaleSize = .7;
      }


    }
    
        
}

